<template>

<div class="report">

	<app-input-text :autogrow="true" v-model="text" placeholder="Enter details of issue..." />

</div>

</template>

<script>

export default {

	data: function() {

		return {
			text: '',
			timer: false
		}

	},

	watch: {

		text: function() {

			clearTimeout(this.timer)

			this.timer = this.$_.delay(this.emitValue.bind(this), 2000)

		}

	},

	methods: {

		emitValue: function() {

			this.$emit('change', this.text)

		}

	}

}

</script>

<style scoped>

.report {
	padding: 10px;
}

.report >>> textarea {
	font-size: 16px;
}

</style>
